<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Uživatelé - Administrátoři - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <a-form :form="form" @submit="handleSubmit">
        <h5 class="text-black mt-2 mb-3">
          <strong>Osobní nastavení</strong>
        </h5>
        <a-form-item
          :required="true"
          label="Ikonka"
        >
          <a-form-item>
            <a-radio-group v-decorator="['avatar', { initialValue: $avatarVectors[0] }]" name="avatar" :class="$style.gallery">
              <a-col :key="item" v-for="item in $avatarVectors" :xl="3" :md="6" :sm="8" :xs="12">
                <div class="mb-4" :class="{[$style.active]: item === form.getFieldValue('avatar'), [$style.item]: 1 === 1, [$style.withControls]: 1 === 1}">
                  <cui-avatar :src="$avatarVectorPath + item" :size="110" :svg="true"/>
                  <div :class="$style.itemControls">
                    <a-radio :class="$style['ant-radio-wrapper']" :value="item"/>
                  </div>
                </div>
              </a-col>
            </a-radio-group>
          </a-form-item>
        </a-form-item>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item
              label="Jméno"
              :validate-status="error('uer_first_name') ? 'error' : ''"
              :help="error('uer_first_name') || ''"
            >
              <a-input v-decorator="['uer_first_name', { rules: [{max: 30, message: 'Jméno nemůže být delší než 30 znaků!'}, { required: true, message: 'Jméno musí být vyplněné!' }]}]"
                       placeholder="Jméno"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="Příjmení"
              :validate-status="error('uer_last_name') ? 'error' : ''"
              :help="error('uer_last_name') || ''"
            >
              <a-input v-decorator="['uer_last_name', { rules: [{max: 40, message: 'Příjmení nemůže být delší než 40 znaků!'}, { required: true, message: 'Příjmení musí být vyplněné!' }]}]"
                       placeholder="Příjmení"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          label="E-mailová adresa"
          :validate-status="error('email') ? 'error' : ''"
          :help="error('email') || ''"
        >
          <a-input
            type="email"
            v-decorator="['email', { rules: [{max: 100, message: 'E-mail nemůže být delší než 100 znaků!'}, { type: 'email', message: 'E-mailová adresa není platný formát!' }, { required: true, message: 'E-mailová adresa musí být vyplněná!' }]}]"
            placeholder="E-mailová adresa"
          />
        </a-form-item>
        <h5 class="text-black mt-2 mb-3">
          <strong>Bezpečnostní nastavení</strong>
        </h5>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item
              label="Heslo"
              :validate-status="error('password') ? 'error' : ''"
              :help="error('password') || ''">
              <a-input
                type="password"
                v-decorator="['password', { rules: [{ validator: validateToNextPassword }, { required: true, message: 'Heslo musí být vyplněné!' }, { min: 6, message: 'Heslo musí mít délku alespoň 6 znaků' }]}]"
                placeholder="Heslo"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="Heslo znovu"
              :validate-status="error('password_again') ? 'error' : ''"
              :help="error('password_again') || ''">
              <a-input
                type="password"
                v-decorator="['password_again', { rules: [{ validator: compareToFirstPassword }, { required: true, message: 'Heslo znovu adresa musí být vyplněné!' }, { min: 6, message: 'Heslo znovu musí mít délku alespoň 6 znaků' }]}]"
                placeholder="Heslo znovu"
                @blur="handleConfirmBlur"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import CuiAvatar from '@/components/CleanUIComponents/Avatar'
import { hasErrors } from '../../../../services/forms'

export default {
  components: { ActionTools, CuiAvatar },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  data: function () {
    return {
      hasErrors,
      loading: false,
      confirmDirty: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/user/admin',
          title: 'Seznam',
        },
      ],
    }
  },
  methods: {
    handleConfirmBlur(e) {
      this.confirmDirty = this.confirmDirty || !!e.target.value
    },
    compareToFirstPassword(rule, value, callback) {
      if (value && value !== this.form.getFieldValue('password')) {
        callback(new Error('Hesla se neshodují!'))
      } else {
        callback()
      }
    },
    validateToNextPassword(rule, value, callback) {
      if (value && this.confirmDirty) {
        this.form.validateFields(['password_again'], { force: true })
      }
      callback()
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('userAdmin/post', this.getData())
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      return {
        email: this.form.getFieldValue('email'),
        avatar: this.form.getFieldValue('avatar'),
        uer_first_name: this.form.getFieldValue('uer_first_name'),
        uer_last_name: this.form.getFieldValue('uer_last_name'),
        password: this.form.getFieldValue('password'),
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
